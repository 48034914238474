<template>
    <div class="MemberDailySettleCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" :inline="true" label-width="85px" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易机构">
                            <el-select
                                placeholder="请选择"
                                v-model="form.deptCode"
                                @change="handleSelectDeptOrDate"
                                style="width: 200px"
                                v-if="depts.length"
                                filterable
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结算日期">
                            <el-date-picker
                                style="width: 200px"
                                v-model="form.settleDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                @change="handleSelectDeptOrDate"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-select
                                placeholder="请选择"
                                v-model="form.operatorCode"
                                @change="handleSelectCashier"
                                style="width: 200px"
                                v-if="depts.length"
                            >
                                <el-option value="" label="暂无选项" v-show="meta.cashiers.length == 0" />
                                <el-option value="" label="请选择" v-show="meta.cashiers.length > 1" />
                                <el-option
                                    v-for="cashier in meta.cashiers"
                                    :label="cashier.realName"
                                    :key="cashier.code"
                                    :value="cashier.code"
                                    :disabled="
                                        form.creatorCode != 'TRSSTF00000000000001' && cashier.username == 'admin'
                                    "
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input placeholder="" readOnly="true" v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <hr />
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="充值人数">
                            <el-input placeholder="" readOnly="true" v-model="form.totalRechargePeople" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收取工本费">
                            <el-input placeholder="" readOnly="true" v-model="form.inWorkCost" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="充值笔数">
                            <el-input placeholder="" readOnly="true" v-model="form.totalRechargeCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="充值总额">
                            <el-input placeholder="" readOnly="true" v-model="form.totalRechargeMoney" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款人数">
                            <el-input placeholder="" readOnly="true" v-model="form.totalRefundPeople" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="支出工本费">
                            <el-input placeholder="" readOnly="true" v-model="form.outWorkCost" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款笔数">
                            <el-input placeholder="" readOnly="true" v-model="form.totalRefundCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款总额">
                            <el-input placeholder="" readOnly="true" v-model="form.totalRefundMoney" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <hr />
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="tableData">
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="支付类型" width="200" prop="templateName" />
                <el-table-column label="支付总额" width="144" prop="money">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.money | money }}</span></template
                    >
                </el-table-column>
            </el-table>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import EfRemark from 'components/EfRemark';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'MemberDailySettleCreate',
    components: { EfRemark },
    data() {
        return {
            tableData: [],
            form: {
                creatorCode: this.$store.state.session.code,
                creator: this.$store.state.session.name,
                totalRechargePeople: '',
                totalRechargeCount: '',
                totalRechargeMoney: '',
                totalRefundPeople: '',
                totalRefundCount: '',
                totalRefundMoney: '',
                inWorkCost: '',
                outWorkCost: '',
                deptCode: '',
                operatorCode: '',
                settleDate: this.formatDate(new Date()),
                remark: '',
                changeCodes: [],
            },
            url: {
                queryPayType: '/finance/member/settle/daily/willDailySettleData',
                queryCashier: '/finance/member/settle/daily/cashier',
                save: '/finance/member/settle/daily/createDailySettle',
            },
            meta: {
                cashiers: [],
            },
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
            depts: [],
        };
    },
    mounted() {
        const _this = this;
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
            if (rst.length == 1) {
                _this.form.deptCode = rst[0].code;
                _this.queryStaff();
            }
        });
    },
    methods: {
        handleSave() {
            const _this = this;
            if (!_this.form.remark) {
                _this.$message.error('请填写备注并不大于200字');
                return;
            }
            if (!_this.form.changeCodes || _this.form.changeCodes.length <= 0) {
                _this.$message.error('当前不存在需要结算的数据');
                return;
            }
            const _params = {
                deptCode: _this.form.deptCode,
                operatorCode: _this.form.operatorCode,
                settleDate: _this.form.settleDate,
                remark: _this.form.remark,
                changeCodes: _this.form.changeCodes,
            };
            UrlUtils.PostRemote(this, this.url.save, _params);
        },
        handleSelectCashier() {
            this.initData();
            this.querySettleData();
        },
        handleSelectDeptOrDate() {
            this.meta.cashiers = [];
            this.form.operatorCode = '';
            this.initData();
            this.queryStaff();
        },
        queryStaff() {
            const _this = this;
            if (_this.form.settleDate && _this.form.deptCode) {
                const _staffParams = { params: { deptCode: _this.form.deptCode, date: _this.form.settleDate } };
                Util.queryTable(_this, _this.url.queryCashier, _staffParams, (data) => {
                    _this.meta.cashiers = data.data;
                    if (data.data.length == 1) {
                        _this.form.operatorCode = data.data[0].code;
                    }
                    _this.querySettleData();
                });
            } else {
                _this.querySettleData();
            }
        },
        querySettleData() {
            const _this = this;
            if (_this.form.settleDate && _this.form.deptCode) {
                const _params = {
                    params: {
                        date: _this.form.settleDate,
                        deptCode: _this.form.deptCode,
                        cashier: _this.form.operatorCode,
                    },
                };
                Util.queryTable(_this, _this.url.queryPayType, _params, (data) => {
                    _this.form.inWorkCost = _this.fmtMoney(data.data.dailySettle.inWorkCost);
                    _this.form.outWorkCost = _this.fmtMoney(data.data.dailySettle.outWorkCost);
                    _this.form.totalRechargeCount = data.data.dailySettle.totalRechargeCount;
                    _this.form.totalRechargeMoney = _this.fmtMoney(data.data.dailySettle.totalRechargeMoney);
                    _this.form.totalRechargePeople = data.data.dailySettle.totalRechargePeople;
                    _this.form.totalRefundCount = data.data.dailySettle.totalRefundCount;
                    _this.form.totalRefundMoney = _this.fmtMoney(data.data.dailySettle.totalRefundMoney);
                    _this.form.totalRefundPeople = data.data.dailySettle.totalRefundPeople;
                    _this.form.changeCodes = data.data.dailySettleDetails.map((d) => d.changeCode);
                    _this.tableData = data.data.dailySettlePays;
                });
            } else {
                _this.$message.error('请选择门店和结算日期');
            }
        },
        initData() {
            this.form.totalRechargePeople = '';
            this.form.totalRechargeCount = '';
            this.form.totalRechargeMoney = '';
            this.form.totalRefundPeople = '';
            this.form.totalRefundCount = '';
            this.form.totalRefundMoney = '';
            this.form.inWorkCost = '';
            this.form.outWorkCost = '';
            this.form.changeCodes = [];
            this.tableData = [];
        },
    },
};
</script>

<style scoped>
.dailySettle_detail .el-form-item {
    margin-bottom: 0;
}
</style>
