<template>
    <el-form-item label="备注" prop="remark" v-bind="$attrs">
        <el-input
            v-model.trim="remark"
            type="textarea"
            v-bind="$attrs"
            style="width: 800px"
            :placeholder="`字数不超过${maxLength}`"
            :show-word-limit="true"
            :maxlength="maxLength"
            :minlength="1"
        />
    </el-form-item>
</template>

<script>
const maxLength = 200;
export default {
    name: 'EfRemark',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            remark: '',
            maxLength: maxLength,
        };
    },
    watch: {
        value: {
            handler(n, o) {
                this.remark = n;
            },
            immediate: true,
        },
        remark: {
            handler(n, o) {
                this.$emit('input', n);
            },
            immediate: true,
        },
    },
};
</script>
