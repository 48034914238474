export const DeptTypeEnum = {
    HEAD: 1,
    REPOSITORY: 2,
    STORE: 3,
    properties: {
        1: { desc: '总部', status: 1 },
        2: { desc: '配送中心', status: 2 },
        3: { desc: '门店', status: 3 },
    },
};
